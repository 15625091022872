export default {
  name: "UserMixins",

  methods: {

    mapUserType(type){
      switch(type){

        case 'GRC_user':
          return 'Consultant'

        case 'Customer':
          return 'Customer'

        case 'Vendor':
          return 'Third Party'

        case 'Portal_user':
          return 'Portal User'

      }
    },

    getCurrentTenantDetails(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/current-tenant-details`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getRolesFromTenant(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/get-roles-from-tenant`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getUserModules(userId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/users/${userId}/user-tenant-module`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateUserBasicDetails(userId,data) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        
        Object.keys(data).map(key=>{
          if(data[key]){
            bodyFormData.append(key, data[key]);
          }
        })
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/users/${userId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    reinviteUser(userId) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append('user_id', userId);

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/users/${userId}/reinvite`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getReinviteUserLink(userId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/users/${userId}/reinvite-link`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateUserModules(userId,data) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        
        Object.keys(data).map(key=>{
          if(data[key]){
            bodyFormData.append(key, data[key]);
          }
        })
     
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/users/${userId}/user-tenant-module/update`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    

    createUser(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstname);
        bodyFormData.append("lastname", obj.lastname);
        bodyFormData.append("email", obj.email);
        bodyFormData.append("mobile", obj.mobile);
        obj.modules && obj.modules.length>0 && obj.modules.map((module, i)=>{
            bodyFormData.append(`modules[${i}][module_id]`, module.id)
            bodyFormData.append(`modules[${i}][role]`, module.role)
        })
        bodyFormData.append("should_invite", obj.should_invite);

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/users`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    addUserToTenant(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstname);
        bodyFormData.append("lastname", obj.lastname);
        bodyFormData.append("email", obj.email);
        bodyFormData.append("mobile", obj.mobile);
        bodyFormData.append("role_id", obj.role.selected);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/add/user`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUsers(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getUserDetails(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users/${id}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getCurrentUserDetails() {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/current-user`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCurrentUserDetails(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstName);
        bodyFormData.append("lastname", obj.lastName);
        // bodyFormData.append("email", obj.email);
        bodyFormData.append("mobile", obj.mobile);
        bodyFormData.append("designation", obj.designation);
        bodyFormData.append("department_id", obj.department);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateUserDetails(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstname);
        bodyFormData.append("lastname", obj.lastname);
        bodyFormData.append("designation", obj.designation);
        bodyFormData.append("department_id", obj.department_id);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user/${obj.userId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setUserActiveStatus(ssoId, status) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("sso_id", ssoId);
        bodyFormData.append("status", status);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user-status`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
