import { isUserLoggedIn } from "@/auth/utils";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

function checkIfUserIsVendor() {
  if (
    store.state.app.user.user_type &&
    store.state.app.user.user_type == "Vendor"
  ) {
    return true;
  } else {
    return false;
  }
}

function guardRoute(to, from, next) {
  let isAuthenticated = false;
  if (isUserLoggedIn()) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    if (
      to.meta.allowedOnWorkspace &&
      !store.state.app.currentWorkspace.enabled_modules.some(
        (module) => module.name == to.meta.allowedOnWorkspace
      )
    ) {
      next("/");
    }
    next();
  } else {
    next("/login"); // go to '/login';
  }
}

function preventAuthenticatedUser(to, from, next) {
  let isAuthenticated = false;
  if (isUserLoggedIn()) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (!isAuthenticated) {
    next();
  }
  else{
    next(from.path)
  }
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Dashboard/Dashboard.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Dashboard",
        vendorAuth: true,
        // breadcrumb: [
        //   {
        //     text: 'Stats & Graphs',
        //     active: true,
        //   },
        // ],
      },
    },

    {
      path: "/app-switch",
      name: "app-switch",
      props: true,
      component: () => import("@/views/AppSwitchLanding.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/2FA/:id",
      name: "TwoFA",
      component: () => import("@/views/Users/TwoFA.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard/Dashboard.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Dashboard",
        // breadcrumb: [
        //   {
        //     text: 'Stats & Graphs',
        //     active: true,
        //   },
        // ],
      },
    },

    {
      path: "/reports/tm/general",
      name: "tm-general-reports",
      component: () => import("@/views/Reports/tm/GeneralReports.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Reports",
        allowedOnWorkspace: "Threat Management",
        breadcrumb: [
          {
            text: "Reports",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tasks",
      name: "tasks",
      component: () => import("@/views/Tasks/Tasks.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Tasks",
        breadcrumb: [
          {
            text: "Tasks",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/UserManagement/UserListing.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Users",
        allowedOnWorkspace: "Secusy App",
        breadcrumb: [
          {
            text: "Users",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/users/:id",
    //   name: "user_details",
    //   component: () => import("@/views/UserManagement/UserDetails.vue"),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: "Details",
    //     breadcrumb: [
    //       {
    //         text: "Users",
    //         path:'/users'
    //       },
    //       {
    //         text: "Details",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/Users/Profile.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Users/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "register",
      beforeEnter: preventAuthenticatedUser,
      component: () => import("@/views/Users/Register.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/workspaces-empty",
      name: "workspaces-empty",
      component: () => import("@/views/noworkspace.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      beforeEnter: preventAuthenticatedUser,
      component: () => import("@/views/Users/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      beforeEnter: preventAuthenticatedUser,
      component: () => import("@/views/Users/ResetPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/create-new-password",
      name: "create-new-password",
      beforeEnter: preventAuthenticatedUser,
      props: true,
      component: () => import("@/views/Users/CreateNewPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    // {
    //   path: '/settings',
    //   name: 'settings',
    //   component: () => import('@/views/Settings/SettingsView.vue'),
    //   beforeEnter: guardRoute,
    //   meta: {
    //     pageTitle: 'App Settings',
    //     breadcrumb: [
    //       {
    //         text: 'Settings',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
